import { useAuth } from 'auth';
import * as React from 'react';
import { BaseLayout } from '.';
import { MainHeader } from './main-header';
import styles from './main-layout.module.css';
import { Helmet } from 'react-helmet-async';
import { useGetMappedUserLanguage } from '../shared/language/use-get-mapped-user-language';

// This is the layout used when logged in (timeline, downloads page etc)
export const MainLayout: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
    const { indeterminate, isAuthenticated } = useAuth();
    const isLoading = indeterminate || !isAuthenticated;
    const lang = useGetMappedUserLanguage();

    return (
        <BaseLayout isLoading={isLoading}>
            <Helmet>
                <html lang={lang ?? 'en'} />
            </Helmet>
            <MainHeader />
            <div className={styles['scrolling-container']}>{children}</div>
        </BaseLayout>
    );
};
