import axios from 'axios';
import { ActivitiesApi, ActivitiesSortEnum, ActivityAthlete, ActivityPeriod, DeepActivity, DeviceInfo } from 'oas';
import { InfiniteData, UseInfiniteQueryResult, UseQueryResult } from '@tanstack/react-query';
import { transformActivity } from '.';
import { mockResponse, useApiQuery } from '../use-api-query';
import { useInfiniteApiQuery } from '../use-infinite-api-query';
import { TfActivity, transformActivityListItem } from './transform';

export const useGetActivity: (
    ...parameters: Parameters<ActivitiesApi['getActivity']>
) => UseQueryResult<TfActivity | null> = (id, include, options = {}) =>
    useApiQuery(['activities', id, include], (apiConfig, axiosInstance) => {
        return id !== ''
            ? new ActivitiesApi(apiConfig, '', axiosInstance).getActivity(id, include, options).then((response) => {
                  if (axios.isAxiosError(response)) {
                      throw response;
                  }

                  return { ...response, data: transformActivity(response.data) };
              })
            : mockResponse({
                  id: '',
                  name: '',
                  modified_at: new Date(),
                  athletes: [],
                  periods: [],
                  athlete_count: 0,
                  period_count: 0,
                  duration: 0,
                  owner_id: '',
                  owner_name: '',
                  is_deleted: 0,
                  start_time: new Date(),
                  end_time: new Date(),
                  full_sync: false,
                  teams: [],
                  tags: [],
              });
    });

export const useGetActivities = (
    pageSize?: number,
    sort?: ActivitiesSortEnum,
    deleted?: number,
    include?: Array<'actdic_status' | 'activity_athletes'>,
    startTime?: number,
    endTime?: number,
    query?: string,
    options = {},
    tags?: Array<string>,
): UseInfiniteQueryResult<InfiniteData<TfActivity[]>> => {
    const myQuery = query || undefined; // prevents empty query= parameter
    return useInfiniteApiQuery(
        ['activities', pageSize, sort, deleted, include, startTime, endTime, myQuery, tags],
        (pageParam, apiConfig, axiosInstance) =>
            new ActivitiesApi(apiConfig, '', axiosInstance)
                .getAllActivities(pageParam || 1, pageSize, sort, deleted, include, startTime, endTime, myQuery, tags)
                .then((response) => ({ ...response, data: response.data.map(transformActivityListItem) })),
        options,
    );
};

export const useGetPeriodsInActivity = (
    ...parameters: Parameters<ActivitiesApi['getPeriodsInActivity']>
): UseQueryResult<ActivityPeriod[]> =>
    useApiQuery(
        ['activities', 'periods', parameters],
        (apiConfig, axiosInstance) =>
            new ActivitiesApi(apiConfig, '', axiosInstance).getPeriodsInActivity(...parameters),
        {
            enabled: parameters[0] !== '',
        },
    );

export const useGetAthletesInActivity = (
    ...parameters: Parameters<ActivitiesApi['getAthletesInActivity']>
): UseQueryResult<ActivityAthlete[]> =>
    useApiQuery(
        ['activities', 'athletes', parameters],
        (apiConfig, axiosInstance) =>
            new ActivitiesApi(apiConfig, '', axiosInstance).getAthletesInActivity(...parameters),
        {
            enabled: parameters[0] !== '',
        },
    );

export const useGetDevicesInActivity = (
    ...parameters: Parameters<ActivitiesApi['getDevices']>
): UseQueryResult<DeviceInfo[]> =>
    useApiQuery(
        ['activities', 'unmapped_devices', parameters],
        (apiConfig, axiosInstance) => new ActivitiesApi(apiConfig, '', axiosInstance).getDevices(...parameters),
        {
            enabled: parameters[0] !== '',
        },
    );

export const useGetDeepActivity = (
    ...parameters: Parameters<ActivitiesApi['getDeepActivity']>
): UseQueryResult<DeepActivity[]> =>
    useApiQuery(['activities', 'deep', parameters], (apiConfig, axiosInstance) =>
        new ActivitiesApi(apiConfig, '', axiosInstance).getDeepActivity(...parameters),
    );
