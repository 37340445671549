import { Alert, AlertActions, AlertItem, Button, DialogProps, Tooltip, Typography } from '@SBGSports/referee-react';
import { TfActivity, useRemoveDemoData } from 'api';
import { useGetDemoDataStatus } from 'api/demo-data/get';
import { useTranslations } from 'i18n';
import React, { useEffect, useMemo, useState } from 'react';
import { ConfirmationModal } from 'shared';
import useLocalStorage from 'use-local-storage';
import styles from './activity-side-menu.module.css';
import { MultiActivitySideMenu } from './multi-activity-side-menu';
import { SingleActivitySideMenu } from './single-activity-side-menu';
import { REPORTING_STATUS_VIEW_PATH, REPORT_TYPE } from 'reporting/constants';
import { useReportTypeStore } from 'shared/state/reporting/reportTypeStore';
import { useRouteMatch } from 'react-router-dom';

interface ActivitySideMenuProps {
    viewPath?: string;
    selectedActivity?: string;
    setSelectedActivity?: (id: string) => void;
    customContent?: React.ReactElement;
    defaultExpanded?: boolean;
    isMultipleActivities?: boolean;
    selectedActivities?: TfActivity[];
    setSelectedActivities?: (activities: TfActivity[]) => void;
    activitiesList?: TfActivity[];
    setActivitiesList?: (activities: TfActivity[]) => void;
}

export const FETCH_ACTIVITIES_PAGE_SIZE = 100;

const defaultSetSelected = () => {
    // Do nothing
};

export let isSideMenuOpen = true;

export const ActivitySideMenu: React.FC<React.PropsWithChildren<ActivitySideMenuProps>> = (props) => {
    const {
        viewPath,
        selectedActivity,
        setSelectedActivity = defaultSetSelected,
        customContent,
        isMultipleActivities = false,
        defaultExpanded = true,
        selectedActivities,
        setSelectedActivities = defaultSetSelected,
        activitiesList,
        setActivitiesList = defaultSetSelected,
    } = props;

    const [isSidemenuOpen, setIsSideMenuOpen] = useState(true);

    useEffect(() => {
        isSideMenuOpen = isSidemenuOpen;
    }, [isSidemenuOpen]);
    const { data: demoDataStatus } = useGetDemoDataStatus();
    const [hasDemoData, setHasDemoData] = useState(false);
    const [hasNonDemoData, setHasNonDemoData] = useState(false);

    const { __ } = useTranslations();
    // Local state
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [neverShow, setNeverShow] = useLocalStorage<boolean>('demo-data-do-not-ask-again', false);
    const dismissAlert = sessionStorage.getItem('demo-data-alert-dismiss') === 'true' ?? false;
    const [showAlert, setShowAlert] = useState<boolean>(false);
    const dialogProps = useMemo<DialogProps>(() => {
        return { header: __('misc.confirm_deletion_demo_data'), size: 'small', open: isOpen };
    }, [__, isOpen]);

    const { mutateAsync: removeDemoData } = useRemoveDemoData();
    const { reportType } = useReportTypeStore();
    const match = useRouteMatch();

    useEffect(() => {
        setHasDemoData(demoDataStatus?.has_demo_data ?? false);
        setHasNonDemoData(demoDataStatus?.has_non_demo_data ?? false);
        setShowAlert((hasDemoData && !dismissAlert && hasNonDemoData && !neverShow) ?? false);
    }, [
        dismissAlert,
        hasDemoData,
        demoDataStatus?.has_non_demo_data,
        neverShow,
        demoDataStatus?.has_demo_data,
        hasNonDemoData,
    ]);

    const renderAlert = () => (
        <Alert>
            <div style={{ pointerEvents: 'auto' }} data-testid={'delete-demo-data-alert'}>
                <AlertItem
                    open={showAlert}
                    title={__('misc.real_data_available')}
                    onClose={() => {
                        sessionStorage.setItem('demo-data-alert-dismiss', 'true');
                        setShowAlert(false);
                    }}
                >
                    <Typography variant="caption-1">{__('misc.delete_demo_data_from_records_message')}</Typography>
                    <AlertActions variant="text">
                        <Typography variant="label-2">
                            <a
                                onClick={() => setIsOpen(true)}
                                style={{
                                    color: 'var(--r-text-body)',
                                    textDecoration: 'underline',
                                }}
                            >
                                {__('misc.delete_demo_data')}
                            </a>
                        </Typography>
                        <Typography variant="label-2">
                            <a
                                onClick={() => setNeverShow(true)}
                                style={{
                                    color: 'var(--r-text-body)',
                                    textDecoration: 'underline',
                                }}
                            >
                                {__('misc.do_not_ask_again')}
                            </a>
                        </Typography>
                    </AlertActions>
                </AlertItem>
            </div>
        </Alert>
    );

    const customFooter: JSX.Element = useMemo(() => {
        return (
            <div className={styles[hasDemoData && hasNonDemoData ? 'button-item' : 'footer-item']}>
                {hasDemoData && hasNonDemoData && (
                    <>
                        <Tooltip text={`${__('misc.delete_demo_data')}`}>
                            <Button
                                role="button"
                                variant="outline"
                                size="small"
                                stretched
                                onClick={() => setIsOpen(true)}
                            >
                                <Typography as="p" variant="button" className={styles['footer-button-text']}>
                                    {__('misc.delete_demo_data')}
                                </Typography>
                            </Button>
                        </Tooltip>
                        <ConfirmationModal
                            handleClose={setIsOpen}
                            handleConfirm={() => {
                                removeDemoData().then(() => {
                                    setIsOpen(false);
                                    setShowAlert(false);
                                    setHasDemoData(false);
                                });
                            }}
                            dialogProps={dialogProps}
                            message={__('misc.delete_demo_data_confirmation_message')}
                            awaitingMessage={__('misc.please_wait_while_delete_demo_data')}
                            isOpen={isOpen}
                            okButtonText={__('misc.delete_confirm')}
                        />
                    </>
                )}
            </div>
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hasDemoData, hasNonDemoData, isOpen]);

    if (
        match.url.includes(REPORTING_STATUS_VIEW_PATH) ||
        (viewPath === REPORTING_STATUS_VIEW_PATH && reportType === REPORT_TYPE.status)
    ) {
        return <>{props.children}</>;
    }

    if (isMultipleActivities) {
        return (
            <MultiActivitySideMenu
                selectedActivities={selectedActivities ?? []}
                setSelectedActivities={setSelectedActivities}
                activitiesList={activitiesList ?? []}
                setActivitiesList={setActivitiesList}
                customContent={customContent}
                pageSize={FETCH_ACTIVITIES_PAGE_SIZE}
                defaultExpanded={defaultExpanded}
                setIsSideMenuOpen={setIsSideMenuOpen}
                customFooter={customFooter}
            >
                {props.children}
                {showAlert && renderAlert()}
            </MultiActivitySideMenu>
        );
    } else {
        return (
            <SingleActivitySideMenu
                viewPath={viewPath ?? ''}
                selectedActivity={selectedActivity ?? ''}
                setSelectedActivity={setSelectedActivity}
                customContent={customContent}
                pageSize={FETCH_ACTIVITIES_PAGE_SIZE}
                defaultExpanded={defaultExpanded}
                setIsSideMenuOpen={setIsSideMenuOpen}
                customFooter={customFooter}
                disabledExpandCollapse={viewPath === REPORTING_STATUS_VIEW_PATH}
            >
                {props.children}
                {showAlert && renderAlert()}
            </SingleActivitySideMenu>
        );
    }
};
